import React from "react";
import {Trans} from "react-i18next";
import ExtensionButton from "./ExtensionButton";
import i18next from "i18next";

function Howto() {
        return (
            <div className="howto section section--howto" id={"howto"} data-offset={-180}>
                <div className="section_inner">
                    <div className="steps">
                        <h2 className="steps_title">
                            <Trans>howto.title</Trans>
                        </h2>
                        <div className="steps_items">
                            <div className={"steps_items_item steps_items_item--1 " + i18next.language }>
                                <span className="num">1</span>
                                <h3 className="title">
                                    <Trans>howto.title.p1.title</Trans>
                                </h3>
                                <p className="info">
                                    <Trans>howto.title.p1.subtitle</Trans>
                                </p>
                            </div>
                            <div className={"steps_items_item steps_items_item--2 " + i18next.language }>
                                <span className="num">2</span>
                                <h3 className="title">
                                    <Trans>howto.title.p2.title</Trans>
                                </h3>
                                <p className="info">
                                    <Trans>howto.title.p2.subtitle</Trans>
                                </p>
                            </div>
                            <div className={"steps_items_item steps_items_item--3 " + i18next.language}>
                                <span className="num">3</span>
                                <h3 className="title">
                                    <Trans>howto.title.p3.title</Trans>
                                </h3>
                                <p className="info">
                                    <Trans>howto.title.p3.subtitle</Trans>
                                </p>
                            </div>
                        </div>
                    </div>

                    <ExtensionButton isBigButton={true} buttonKey={3}/>

                </div>
            </div>
        )
}

export default Howto;