import React from "react";
import ExtensionButton from './ExtensionButton.js';
import {Trans} from 'react-i18next';
import FaqList from "./FaqList";
import Howto from "./Howto";
import Features from "./Features";

function Main() {
    return (
        <div className="wrap clearfix">

        <div className="main section section--main">
            <div className="section_bg">
                <div className="section_inner" id="redmi6a-wrapper">
                    <div className="main_info">
                        <h1 className="title">
                            <span className="big"><Trans>mainPage.h1.p1</Trans></span>
                            <Trans>mainPage.h1.p2</Trans>
                        </h1>
                        <p className="title_text">
                            <Trans>mainPage.h1.p3</Trans>
                        </p>

                        <ExtensionButton isBigButton={true} buttonKey={1}/>

                    </div>
                    <section>
                        <div className="main_history">
                            <div className="history">
                                <div className="status">
                                    <Trans>mainPage.priceDecrease</Trans>
                                </div>
                                <div className="price">
                                    <span className="price_from">82$</span>
                                    <span className="price_arrow icon icon--price_arrow"/>
                                    <span className="price_to">75$</span>
                                </div>
                                <div className="rating">
                                    <span className="val">95%</span>
                                    <span className="text">
                                        <Trans>mainPage.selerRating.high</Trans>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div id="redmi6a">
                            <img src="/img/redmi6a.webp" width="75%" height="75%" alt="Xiaomi Redmi 6a"/>
                        </div>
                    </section>
                </div>
            </div>
        </div>

        <Features />
        <Howto />
        <FaqList />

        <div className="footer-empty"/>
    </div>
    )
}

export default Main;
