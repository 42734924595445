import React, { useState, useEffect, useRef } from "react";
import './css/languageSelector.css';
import './../../i18n';
import i18next from "i18next";

function LanguageSelector(props) {
    const ref = useRef(null)
    const languagesList = [
        {
            code: "en",
            label: "English, EN",
            img: "/img/flags/gb.svg"
        },
        {
            code: "ru",
            label: "Русский, RU",
            img: "/img/flags/ru.svg"
        },
        {
            code: "pl",
            label: "Polski, PL",
            img: "/img/flags/pl.svg"
        },
        {
            code: "it",
            label: "Italiana, IT",
            img: "/img/flags/it.svg"
        },
        {
            code: "fr",
            label: "Français, FR",
            img: "/img/flags/fr.svg"
        },
        {
            code: "es",
            label: "Español, ES",
            img: "/img/flags/es.svg"
        },
    ];
    const [selectedLanguage, setSelectedLanguage] = useState(languagesList[0]);
    const [showSelect, setShowSelect] = useState(false);

    function toggleButton() {
        setShowSelect(!showSelect);
    }

    function onBlur(e) {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowSelect(false);
        }
    }

    function changeLanguage(language){
        setSelectedLanguage(language);
        i18next.changeLanguage(language.code);
        setShowSelect(!showSelect);
    }

    useEffect(() => {
        let languageCode = i18next.language.split("-")[0];
        let language = languagesList.find(x => x.code === languageCode);
        if (language) {
            setSelectedLanguage(language);
        }
    }, [i18next.language])

    return (
        <div className="flag-select " ref={ref}  onBlur={onBlur}>
            <button className="flag-select__btn" id="select_flag_button" type="button" aria-haspopup="listbox"
                    aria-expanded={showSelect} aria-labelledby="select_flag_button" onClick={toggleButton}>
                <span className="flag-select__option flag-select__option--placeholder">
                    <img className="flag-select__option__icon" src={selectedLanguage.img} alt={selectedLanguage.code}/>
                    <span className="flag-select__option__label">{selectedLanguage.label}</span>
                </span>
                <span className="caret"/>
            </button>
            <ul tabIndex="0" role="listbox" className={"flag-select__options " + (showSelect ? "" : "hidden")}>
                {languagesList.map((language) => {
                    return (
                    <li
                        role="option"
                        tabIndex="1"
                        id={"select_flag_" + language.code}
                        key={"select_flag_" + language.code}
                        className="flag-select__option has-label"
                        onClick={() => changeLanguage(language)}
                    >
                        <span>
                            <img className="flag-select__option__icon" alt={language.label} src={language.img}/>
                            <span className="flag-select__option__label">{language.label}</span>
                        </span>
                    </li>
                    )
                })}
            </ul>
        </div>
    );
}

export default LanguageSelector;
