import React from 'react';

function ExternalRedirect() {
    //Redirect to play market for android devices
    if (/android/i.test(window.navigator.userAgent)) {
        window.location.href = 'https://test12343.onelink.me/ZI8w/nw1l7iix';
    }

    return null;
}

export default ExternalRedirect;
