import React from 'react';
import {Helmet} from 'react-helmet';
import Footer from './LandingPage/Footer.js';
import Header from './LandingPage/Header.js';
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import i18next from "i18next";

function Welcome(props) {
  const {t} = useTranslation(['welcome', 'translation']);
  return [
    <Helmet key={1} htmlAttributes={{ lang : i18next.language }}>
      <title>{t("welcomePage.title")}</title>
    </Helmet>,
    <Header disableMenu={true} key={2}/>,
    <div className="main section section--main welcome-page" key={3}>
      <div className="section_bg">
        <div className="section_inner" id="redmi6a-wrapper">
          <div className="main_info">
            <h1 className="title">
              <Trans i18nKey="welcomePage.pageTitle" t={t}/>
            </h1>
            <p className="title_text">
              <Trans i18nKey="welcomePage.pageSubtitle" t={t}/>
            </p>

            <Link to={"/onboarding"} className="button button--default support_popup_form_button">
              <span className="button_bg">
                <span className="button_text text_large">{t("welcomePage.buttonText")}</span>
              </span>
            </Link>

          </div>
          <section>
            <div id="redmi6a">
              <img src="/img/welcome_pic.png" width="75%" height="75%" alt="Welcome"/>
            </div>
          </section>
        </div>
      </div>
    </div>,
    <Footer translationNs={"welcome"} key={4}/>
  ]
}

export default Welcome;
