import React from 'react';
import './LandingPage/css/agreement.css'
import {Trans, useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import i18next from "i18next";

function Agreement() {
    const {t} = useTranslation('agreement');
    return [
      <Helmet key={1} htmlAttributes={{ lang : i18next.language }}>
        <title>{t("pageTitle")}</title>
      </Helmet>,
      <div className="agreement_page">
        <div className="argeement_content">
          <h1>{t("title")}</h1>
          <p><Trans i18nKey="part.p1" t={t}/></p>
          <p>{t("part.p2")}</p>
          <p>{t("part.p3")}</p>
          <p>{t("part.p4")}</p>
          <p>{t("part.p5")}</p>
          <h3>{t("header")}</h3>
          <p>{t("part.p6")}</p>
          <p><Trans i18nKey="part.p7" t={t}/></p>
          <p><Trans i18nKey="part.p8" t={t}/></p>
          <p><Trans i18nKey="part.p9" t={t}/></p>
          <p><Trans i18nKey="part.p10" t={t}/></p>
          <p><Trans i18nKey="part.p12" t={t}/></p>
          <p><Trans i18nKey="part.p12" t={t}/></p>
          <p><Trans i18nKey="part.p13" t={t}/></p>
          <p><Trans i18nKey="part.p14" t={t}/></p>
          <p><Trans i18nKey="part.p15" t={t}/></p>
          <p>{t("part.p16.1")}<br/>{t("part.p16.2")}</p>
          <ul>
            <li><h3>{t("header1")}</h3></li>

            <li>{t("part1.p1")}</li>
            <li>{t("part1.p2")}</li>
            <li>{t("part1.p3")}</li>
            <li>{t("part1.p4")}</li>
            <li>{t("part1.p5")}</li>
            <li>{t("part1.p6")}</li>
            <li>{t("part1.p7")}</li>
            <li>{t("part1.p8")}</li>
            <li>{t("part1.p9")}</li>

            <li><h3>{t("header2")}</h3></li>

            <li>{t("header2.sub1")}</li>
            <li>{t("part2.p1")}</li>
            <li>{t("part2.p2")}</li>
            <li>{t("part2.p3")}</li>
            <li>{t("part2.p4")}</li>
            <li>{t("part2.p5")}</li>
            <li>{t("part2.p6")}</li>
            <li>{t("part2.p7")}</li>
            <li>{t("part2.p8")}</li>
            <li>{t("part2.p9")}</li>
            <li>{t("part2.p10")}</li>
            <li>{t("part2.p11")}</li>

            <li>{t("header2.sub2")}</li>
            <li>{t("part2.p12")}</li>
            <li>{t("part2.p13")}</li>
            <li>{t("part2.p14")}</li>
            <li>{t("part2.p15")}</li>
            <li>{t("part2.p16")}</li>
            <li>{t("part2.p17")}</li>
            <li>{t("part2.p18")}</li>
            <li>{t("part2.p19")}</li>


            <li><h3>{t("header3")}</h3></li>
            <li>{t("part3.p1")}</li>
            <li>{t("part3.p2")}</li>
            <li>{t("part3.p3")}</li>
            <li>{t("part3.p4")}</li>
            <li>{t("part3.p5")}</li>
            <li>{t("part3.p6")}</li>
            <li>{t("part3.p7")}</li>
            <li>{t("part3.p8")}</li>
            <li>{t("part3.p9")}</li>
            <li>{t("part3.p10")}</li>


            <li><h3>{t("header4")}</h3></li>
            <li>{t("part4.p1")}</li>
            <li>{t("part4.p2")}</li>
            <li>{t("part4.p3")}</li>
            <li>{t("part4.p4")}</li>
            <li>{t("part4.p5")}</li>
            <li>{t("part4.p6")}</li>
            <li>{t("part4.p7")}</li>
            <li>{t("part4.p88")}</li>
            <li>{t("part4.p9")}</li>
            <li>{t("part4.p10")}</li>
            <li>{t("part4.p11")}</li>
            <li>{t("part4.p12")}</li>
            <li>{t("part4.p13")}</li>
            <li>{t("part1.p14")}</li>
            <li>{t("part4.p15")}</li>
            <li>{t("part4.p16")}</li>

            <li><h3>{t("header5")}</h3></li>
            <li>{t("part5.p1")}</li>
            <li>{t("part5.p2")}</li>

            <li><h3>{t("header6")}</h3></li>
            <li>{t("part6.p1")}</li>
            <li>{t("part6.p2")}</li>

            <li><h3>{t("header7")}</h3></li>
            <li>{t("part7.p1")}</li>
            <li>{t("part7.p2")}</li>
            <li>{t("part7.p3")}</li>
            <li>{t("part7.p4")}</li>
            <li>{t("part7.p5")}</li>
            <li>{t("part7.p6")}</li>
            <li>{t("part7.p7")}</li>

            <li><h3>{t("header8")}</h3></li>
            <li>{t("part8.p1")}</li>
            <li>{t("part8.p2")}</li>
            <li>{t("part8.p3")}</li>
            <li>{t("part8.p4")}</li>
            <li>{t("part8.p5")}</li>
            <li>{t("part8.p6")}</li>
            <li>{t("part8.p7")}</li>
            <li>{t("part8.p8")}</li>
          </ul>

          <p>
            <b>{t("developer")}</b><br/>
            shopping-helper.io/ pricetracking.helper@gmail.com<br/>
            <b>{t("lastUpdateDateTitle")}</b><br/>
            {t("lastUpdateDate")}
          </p>
        </div>
      </div>
    ]
}

export default Agreement;
