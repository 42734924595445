import React, {useEffect} from 'react';
import {useCookies} from "react-cookie";
import {Helmet} from 'react-helmet';
import Footer from './Footer.js';
import Main from './Main.js';
import Header from './Header.js';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function LandingPage() {
  const {t} = useTranslation();
  const [cookies, setCookie] = useCookies(["utmParams", "installUrl"]);

  useEffect(() => {
    if (!cookies.utmParams) {
      let queryParams = new URLSearchParams(window.location.search);
      setCookie("utmParams", {
        utm_source: queryParams.get("utm_source"),
        utm_medium: queryParams.get("utm_medium"),
        utm_campaign: queryParams.get("utm_campaign")
      },
      {
        "max-age": 3600 * 24,
        "path": "/"
      })
    }

    if (!cookies.installUrl) {
      setCookie(
          'installUrl',
          document.location.href,
          {
            "max-age": 60 * 60 * 2,
            "path": "/"
          }
      );
    }
  })

  return [
    <Helmet key={1} htmlAttributes={{ lang : i18next.language, class: i18next.language}} >
      <script async src="/js/all.js" />
      <title>{t("main.page.title")}</title>
    </Helmet>,
    <Header key={2} />,
    <Main key={3} />,
    <Footer key={4} />,
  ]
}

export default LandingPage;
