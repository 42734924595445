import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import ExtensionButton from "./ExtensionButton";
import SimilarProducts from "./SimilarProducts";
import FavoriteProducts from "./FavoriteProducts";
import "./css/scrollbar.css";

function Features(props) {
    const {t} = useTranslation(["features", "translation"])
    const tabs = [
        {
            id: 0,
            className: "tabs_item rating_features_item",
            tab: "rating_tab",
            contentId: "section-1",
            icon: "rating",
            title: "feature1.title",
            content: ["feature1.content.par1",  "feature1.content.par2"]
        },
        {
            id: 1,
            className: "tabs_item price_dynamics_features_item",
            tab: "price_dynamics_tab",
            contentId: "section-2",
            icon: "history",
            title: "feature2.title",
            content: ["feature2.content.par1",  "feature2.content.par2", "feature2.content.par3"]
        },
        {
            id: 2,
            className: "tabs_item my_goods_features_item",
            tab: "my_goods_tab",
            contentId: "section-3",
            icon: "favorites",
            title: "feature3.title",
            content: ["feature3.content.par1"]
        },
        {
            id: 3,
            className: "tabs_item similar_goods_features_item",
            tab: "similar_goods_tab",
            contentId: "section-4",
            icon: "similar",
            title: "feature4.title",
            content: ["feature4.content.par1",  "feature4.content.par2", "feature4.content.par3"]
        }
    ];
    const [activeTab, setActiveTab] = useState(tabs[0]);

    function activateTab(tabId) {
        let tab = tabs[tabId];
        setActiveTab(tab);
    }

    function activateLink(event, tabId) {
        event.preventDefault();
        activateTab(tabId);
    }

    useEffect(() => {
        window.dispatchEvent(new Event('tabShowed'));
    })

    return (
            <div className="features section section--features" id="wrapper-slider">
                <div className="section_inner" id={"features"}>
                    <div className="tabs">
                        <h2 className="tabs_title">
                            <Trans>features.extensionOptions</Trans>
                        </h2>
                        <div className="tabs_items">
                            {tabs.map((tab, index) => {
                                return <div
                                    className={tab.className + (
                                        activeTab && activeTab.id === tab.id ? " tabs_item_active" : ""
                                    )}
                                    onClick={() => activateTab(tab.id)}
                                    key={"features-tab-" + index}
                                >
                                    <span className={"icon icon--" + tab.icon}/>
                                    <span className="text"><Trans i18nKey={tab.title} t={t}/></span>
                                </div>
                            })}
                        </div>

                        <ExtensionButton isBigButton={false} buttonKey={2} />
                    </div>
                    <div className="browser">

                        <div className="alihelper_demo">
                            <div data-lang="ru"
                                 className="alihelper_plugin_wrap rating_calculated alihelper_plugin_wrap__rating--hight alihelper_plugin_wrap--done tabs_inited">
                                <div className="plugin_body plugin_body--content">
                                    <nav>
                                        <div
                                            className={"rating_tab " +
                                            (activeTab && activeTab.contentId === "section-1" ? "tab-current" : "nav_item")
                                            }
                                        >
                                            <a
                                                className="seller_rating"
                                                href="#section-1"
                                                onClick={(event) => activateLink(event, 0)}
                                            >
                                                <p><Trans>features.sellerRating</Trans></p>
                                                <span className="alihelper_rating_result_score">95%</span>
                                            </a>
                                        </div>
                                        <div className="tabs_wrap">
                                            <div
                                                className={"price_dynamics_tab " +
                                                (activeTab && activeTab.contentId === "section-2" ? "tab-current" : "nav_item")
                                                }
                                            >
                                                <a
                                                    className="price_dynamics price_dynamics--down"
                                                    href="#section-2"
                                                    onClick={(event) => activateLink(event, 1)}
                                                />
                                            </div>
                                            <div
                                                className={"my_goods_tab " +
                                                    (activeTab && activeTab.contentId === "section-3" ? "tab-current" : "nav_item")
                                                }
                                            >
                                                <a
                                                    className="my_goods"
                                                    href="#section-3"
                                                    onClick={(event) => activateLink(event, 2)}
                                                />
                                            </div>
                                            <div
                                                className={"my_goods_tab " +
                                                (activeTab && activeTab.contentId === "section-4" ? "tab-current" : "nav_item")
                                                }
                                            >
                                                <a
                                                    className="similar_goods"
                                                    href="#section-4"
                                                    onClick={(event) => activateLink(event, 3)}
                                                />
                                            </div>
                                        </div>
                                        <div className="minimize"><span className="minimize_btn closed"/></div>
                                    </nav>
                                    <div className="content-wrap">
                                        <div
                                            id="section-1"
                                            className={(activeTab && activeTab.contentId === "section-1") ? "content-current" : "nav_item"}
                                        >
                                            <div className="title_block title_block--rating clearfix rating--hight">
                                                <span className="setting alihelper_popup_settings_icon">
                                                    <span className="tooltip_text"><Trans>features.settings</Trans></span>
                                                </span>
                                                <div className="rating__scale" data-percent="95">
                                                    <div className="rating__scale__wrap">
                                                        <div className="rating__scale__fill"/>
                                                        <div className="rating__scale__value">
                                                            <span className="rating__scale__num">95</span>
                                                            <span className="small">%</span>
                                                            <span className="rating__scale__text">
                                                                <Trans>features.sellerRating.high</Trans>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <span className="rating__scale__fill rating__scale__fill--shadow"/>
                                                </div>
                                                <p className="title">{t("sellersRating.part1")}</p>
                                                <span className="sub_title">{t("sellersRating.part2")}</span>
                                            </div>
                                            <div className="instructions_block"><p
                                                className="instructions_block_inner">
                                                <Trans>features.ratingResult.high</Trans>
                                            </p></div>
                                            <div className="information_seller">
                                                <ul className="information_seller__list">
                                                    <li className="information_seller__item information_seller__item--good">
                                                        <span className="information_seller__item__text">
                                                            <Trans>features.sellerRating.age</Trans> <u><Trans>features.sellerRating.overTwoYears</Trans></u>
                                                        </span>
                                                    </li>
                                                    <li className="information_seller__item information_seller__item--good">
                                                        <span className="information_seller__item__text">
                                                            <Trans>features.sellerRating.manySatisfiedCustomers</Trans>
                                                        </span>
                                                    </li>
                                                    <li className="information_seller__item information_seller__item--good">
                                                        <span className="information_seller__item__text">
                                                            <Trans>features.sellerRating.customersSatisfyCommunication</Trans>
                                                        </span>
                                                    </li>
                                                    <li className="information_seller__item information_seller__item--good">
                                                        <span className="information_seller__item__text">
                                                            <Trans>features.sellerRating.matchDescription</Trans>
                                                        </span>
                                                    </li>
                                                    <li className="information_seller__item information_seller__item--good">
                                                        <span className="information_seller__item__text">
                                                            <Trans>features.sellerRating.shipQuickly</Trans>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div
                                            id="section-2"
                                            className={(activeTab && activeTab.contentId === "section-2") ? "content-current" : "nav_item"}
                                        >
                                            <div className="title_block title_block--price">
                                                <span className="setting alihelper_popup_settings_icon">
                                                    <span className="tooltip_text"><Trans>features.settings</Trans></span>
                                                </span>
                                                <p className="title"><Trans>features.chart.title.p1</Trans></p>
                                                <span className="sub_title"><Trans>features.chart.title.p2</Trans></span>
                                                <div className="chart_style-wrap">
                                                    <div
                                                        id="alihelperPriceChart"
                                                        className="alihelper-price-chart chart_style jqplot-target"
                                                    />
                                                    <div className="price-status price-status--decrease">
                                                        <span className="status-text"><Trans>features.priceDecrease</Trans></span>
                                                        <div className="price-status-val decrease"><span
                                                            className="price-val decrease"><span
                                                            className="curr">$</span>2.38</span> <span
                                                            className="percent"> / 13.3%</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="follow_price_block">
                                                <span className="follow_price beh_price">
                                                    <i className="icon"/>
                                                    <span className="text"><Trans>features.chart.buttonText</Trans></span>
                                                </span>
                                                <p className="follow_price_info">
                                                <Trans>features.chart.foterText</Trans>
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            id="section-3"
                                            className={(activeTab && activeTab.contentId === "section-3") ? "content-current" : "nav_item"}
                                        >
                                            <FavoriteProducts />
                                        </div>
                                        <div
                                            id="section-4"
                                            className={(activeTab && activeTab.contentId === "section-4") ? "content-current" : "nav_item"}
                                        >
                                            <SimilarProducts />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="browser_tooltip">
                                <div className="browser_tooltip_inner">
                                    <h2 className="browser_tooltip_title">
                                        <Trans i18nKey={activeTab.title} t={t}/>
                                    </h2>
                                    <div className="browser_tooltip_info">
                                        {activeTab.content.map((item, index) =>
                                            <p key={index}><Trans i18nKey={item} t={t}/></p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default Features;