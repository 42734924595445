import React, {useState} from "react";
import {Formik, Field, Form} from 'formik';
import {useTranslation} from "react-i18next";

const SupportForm = (props) => {
    const formUrl = "https://api.track4you.tech/api/fe/supports";
    const [dropdownShowed, setDropdownShowed] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState("");
    const {t} = useTranslation();
    const subjectList = [
        {value:"", label: t("supportPopup.subject.placeholder")},
        {value:"questions", label: t("supportPopup.subject.questions")},
        {value:"bugs", label: t("supportPopup.subject.bugs")},
        {value:"ideas", label: t("supportPopup.subject.ideas")},
        {value:"cashback", label: t("supportPopup.subject.cashback")},
        {value:"partnership", label: t("supportPopup.subject.partnership")},
        {value:"other", label: t("supportPopup.subject.other")},
    ];

    function toggleDropdown() {
        setDropdownShowed(!dropdownShowed);
    }

    function onSelectSubject(value) {
        setSelectedSubject(value);
    }

    function sendForm(bodyParams) {
        fetch(formUrl, {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: new URLSearchParams(bodyParams).toString()
        })
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                subject: selectedSubject,
                email: '',
                message: '',
            }}
            onSubmit={async (values) => {
                sendForm({
                    "App_support[subject]": values.subject,
                    "App_support[email]": values.email,
                    "App_support[message]": values.message
                });
                props.onSubmit(true);
            }}
            dirty={true}
        >
            <Form name={"App_support"} method={"post"} className={"support_popup_form"}>
                <div className="support_popup_form_field support_popup_form_field--subject select_wrap">
                    <label htmlFor="App_support_subject">{t("supportPopup.subject")}</label>
                    <select as={'select'} id="App_support_subject" name="subject" required="required"
                            className="form-control" style={{display: "none"}} value={selectedSubject} readOnly>
                        {subjectList.map((item, index) => {
                            return (
                                <option key={item.value} value={item.value}>{item.label}</option>
                            )
                        })}
                    </select>
                    <div className={"ali-nice-select form-control " + (dropdownShowed ? "open" : "")} tabIndex="0" onClick={toggleDropdown}>
                        <span className="current">{subjectList.find(x => x.value === selectedSubject).label}</span>
                        <ul className="list">
                            {subjectList.map((item, index) => {
                                return (
                                    <li
                                        key={'supprt-form-list-item' + item.value}
                                        data-value={item.value}
                                        className={"option" + (selectedSubject === item.value ?  " selected focus" : "")}
                                        onClick={() => onSelectSubject(item.value)}
                                        >
                                        {item.label}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <label htmlFor="App_support_email" className="support_popup_form_field support_popup_form_field--email">
                    <Field
                        type="email"
                        id="App_support_email"
                        name="email"
                        required="required"
                        className="input form-control"
                        placeholder={t("supportPopup.email")}
                    />
                </label>

                <label htmlFor="App_support_message"
                       className="support_popup_form_field support_popup_form_field--message">
                        <Field
                            id="App_support_message"
                            name="message"
                            required="required"
                            as="textarea"
                            cols="30"
                            rows="10"
                            placeholder={t("supportPopup.message")}
                            className="form-control"
                        />
                </label>
                <button type="submit" className="button button--default support_popup_form_button">
                        <span className="button_bg">
                            <span className="button_text">{t('supportPopup.sendButton')}</span>
                        </span>
                </button>
            </Form>
        </Formik>
    )
};

export default SupportForm;