import React from 'react';
import { hydrate, render } from "react-dom";
import { createBrowserHistory } from 'history';
import App from './components/App';
import './i18n';

const history = createBrowserHistory();
// eslint-disable-next-line no-restricted-globals
const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];

if (path) {
  history.replace(path);
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        rootElement
    );
} else {
    render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        rootElement
    );
}

