import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";

function FaqList() {
    const [activeItem, setActiveItem] = useState(null);
    const {t} = useTranslation();
    const items = [
        {
            title: t("qa.question1"),
            text: t("qa.answer1")
        },
        {
            title: t("qa.question2"),
            text: t("qa.answer2.p1"),
            additionalText: t("qa.answer2.p2")
        },
        {
            title: t("qa.question3"),
            text: t("qa.answer3")
        },
        {
            title: t("qa.question4"),
            text: t("qa.answer4")
        },
        {
            title: t("qa.question5"),
            text: t("qa.answer5")
        },
        {
            title: t("qa.question6"),
            text: t("qa.answer6")
        }

    ];

    function activateItem(index) {
        setActiveItem(index);
    }

    function toggleItem(event, index) {
        if (activeItem === index) {
            setActiveItem(null);
            event.stopPropagation();
        }
    }

    return (
        <div className="faq section section--faq" id={"faq"}>
            <div className="section_inner">
                <h2 className="faq_title">
                    <Trans>qa.title</Trans>
                </h2>
                <div className="faq_list">
                    {items.map((item, index) =>
                        <div
                            className={"faq_list_item " + (activeItem === index ? "faq_list_item--active": "")}
                            key={'faq_item_' + index}
                            onClick={() => activateItem(index)}
                        >
                            <h3 className="title">
                                {item.title}
                            </h3>
                            <div className="text">
                                <p>{item.text}</p>
                                {item.additionalText ? <p>{item.additionalText}</p> : ""}
                            </div>
                            <span className="icon icon--faq_arrow" onClick={(event) => toggleItem(event, index)}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FaqList;