import React, {useEffect} from 'react';
import ReactPixel from 'react-facebook-pixel';
import ym, {YMInitializer} from "react-yandex-metrika";
import { withRouter } from 'react-router-dom'
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module'

function Tracking(props) {
    let pathname = props.location.pathname;
    ReactGA.initialize('UA-74244948-2');
    ReactPixel.init('1124644834676437');
    ReactPixel.init('278502837421539');

    useEffect(() => {
        ym('hit', pathname);
        ReactGA.pageview(pathname);
        ReactPixel.pageView();
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pagePath: pathname,
            },
        });
        if (pathname === '/welcome') {
            ReactPixel.track('Lead');
        }
    }, [pathname])

    return <YMInitializer accounts={[69725119]} options={{
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true
    }} key={3}/>;
}

export default withRouter(Tracking);
