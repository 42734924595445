import React from "react";
import SimpleBar from "simplebar-react";
import {useTranslation} from "react-i18next";
import NiceSelect from "./NiceSelect";

function SimilarProducts() {
    const {t} = useTranslation('features');
    const similarItems = [
        {
            title: t("similarProductTitle0"),
            imageUrl: "/img/products/similarProductImg0.jpg",
            priceDynamic: 'more',
            price: '167.50',
            sellerRating: '95'
        },
        {
            title: t("similarProductTitle1"),
            imageUrl: "/img/products/similarProductImg1.jpg",
            priceDynamic: "more",
            price: "211.25",
            sellerRating: "95"
        },
        {
            title: t("similarProductTitle2"),
            imageUrl: "/img/products/similarProductImg2.jpg",
            priceDynamic: "less",
            price: "45.59",
            sellerRating: "95"
        },
        {
            title: t("similarProductTitle3"),
            imageUrl: "/img/products/similarProductImg3.jpg",
            priceDynamic: "more",
            price: "159.99",
            sellerRating: "94"
        },
        {
            title: t("similarProductTitle4"),
            imageUrl: "/img/products/similarProductImg4.jpg",
            priceDynamic: "more",
            price: "109.99",
            sellerRating: "94"
        },
        {
            title: t("similarProductTitle5"),
            imageUrl: "/img/products/similarProductImg5.jpg",
            priceDynamic: "more",
            price: "109.67",
            sellerRating: "94"
        },
        {
            title: t("similarProductTitle6"),
            imageUrl: "/img/products/similarProductImg6.jpg",
            priceDynamic: "more",
            price: "85.99",
            sellerRating: "94"
        },
        {
            title: t("similarProductTitle7"),
            imageUrl: "/img/products/similarProductImg7.jpg",
            priceDynamic: "more",
            price: "135.99",
            sellerRating: "93"
        },
        {
            title: t("similarProductTitle8"),
            imageUrl: "/img/products/similarProductImg8.jpg",
            priceDynamic: "more",
            price: "135.99",
            sellerRating: "93"
        },
        {
            title: t("similarProductTitle9"),
            imageUrl: "/img/products/similarProductImg9.jpg",
            priceDynamic: "more",
            price: "85.99",
            sellerRating: "93"
        },
        {
            title: t("similarProductTitle10"),
            imageUrl: "/img/products/similarProductImg10.jpg",
            priceDynamic: "more",
            price: "85.99",
            sellerRating: "93"
        },
        {
            title: t("similarProductTitle11"),
            imageUrl: "/img/products/similarProductImg11.jpg",
            priceDynamic: "more",
            price: "85.59",
            sellerRating: "92"
        },
        {
            title: t("similarProductTitle12"),
            imageUrl: "/img/products/similarProductImg12.jpg",
            priceDynamic: "more",
            price: "85.63",
            sellerRating: "92"
        },
        {
            title: t("similarProductTitle13"),
            imageUrl: "/img/products/similarProductImg13.jpg",
            priceDynamic: "less",
            price: "60.89",
            sellerRating: "91"
        },
        {
            title: t("similarProductTitle14"),
            imageUrl: "/img/products/similarProductImg14.jpg",
            priceDynamic: "more",
            price: "88.08",
            sellerRating: "91"
        },
        {
            title: t("similarProductTitle15"),
            imageUrl: "/img/products/similarProductImg15.jpg",
            priceDynamic: "less",
            price: "70.99",
            sellerRating: "91"
        },
        {
            title: t("similarProductTitle16"),
            imageUrl: "/img/products/similarProductImg16.jpg",
            priceDynamic: "less",
            price: "79.98",
            sellerRating: "87"
        },
        {
            title: t("similarProductTitle17"),
            imageUrl: "/img/products/similarProductImg17.jpg",
            priceDynamic: "more",
            price: "182.99",
            sellerRating: "87"
        },
        {
            title: t("similarProductTitle18"),
            imageUrl: "/img/products/similarProductImg18.jpg",
            priceDynamic: "less",
            price: "46.99",
            sellerRating: "86"
        },
        {
            title: t("similarProductTitle19"),
            imageUrl: "/img/products/similarProductImg19.jpg",
            priceDynamic: "less",
            price: "0.65",
            sellerRating: "86"
        },
        {
            title: t("similarProductTitle20"),
            imageUrl: "/img/products/similarProductImg20.jpg",
            priceDynamic: "more",
            price: "104.78",
            sellerRating: "84"
        },
        {
            title: t("similarProductTitle21"),

            imageUrl: "/img/products/similarProductImg21.jpg",
            priceDynamic: "more",
            price: "88.79",
            sellerRating: "83"
        },
        {
            title: t("similarProductTitle22"),
            imageUrl: "/img/products/similarProductImg22.jpg",
            priceDynamic: "less",
            price: "2.99",
            sellerRating: "82"
        },
        {
            title: t("similarProductTitle23"),
            imageUrl: "/img/products/similarProductImg23.jpg",
            priceDynamic: "less",
            price: "82.99",
            sellerRating: "80"
        },
        {
            title: t("similarProductTitle24"),
            imageUrl: "/img/products/similarProductImg24.jpg",
            priceDynamic: "more",
            price: "94.99",
            sellerRating: "80"
        },
        {
            title: t("similarProductTitle25"),
            imageUrl: "/img/products/similarProductImg25.jpg",
            priceDynamic: "more",
            price: "97.37",
            sellerRating: "80"
        },
        {
            title: t("similarProductTitle26"),
            imageUrl: "/img/products/similarProductImg26.jpg",
            priceDynamic: "less",
            price: "52.44",
            sellerRating: "71"
        },
        {
            title: t("similarProductTitle27"),
            imageUrl: "/img/products/similarProductImg27.jpg",
            priceDynamic: "less",
            price: "80.49",
            sellerRating: "55"
        },
        {
            title: t("similarProductTitle28"),
            imageUrl: "/img/products/similarProductImg28.jpg",
            priceDynamic: "less",
            price: "79.99",
            sellerRating: "37"
        }
    ];
    const filterList = [
        t("sort.byRating"),
        t("sort.byPrice")
    ];

    return (
        <div>
            <div className="title_block title_block--similar">
                <p className="title">
                    {t("title.similar.part1")} <span className="similar_count"> (26)</span>
                </p>
                <span className="sub_title"> {t("title.similar.part2")}</span>
                <div className="select_wrap">
                    <NiceSelect items={filterList} />
                </div>
            </div>
            <SimpleBar className="goods_block goods_block--similar ss-container filter--rating" id="similar_block_content">
                <div className="goods_block goods_block--similar ss-container filter--rating">
                    <div className="ss-wrapper">
                        <div className="ss-content">
                            {similarItems.map((item, index) => {
                                return <a className="similar_item" href="" title={item.title} key={"similar-product-" + index}  onClick={(e) => {e.preventDefault()}}>
                                    <div className="img-wrap">
                                        <img alt={item.title} className="img" src={item.imageUrl}/>
                                    </div>
                                    <div className="bot">
                                        <span className={"price_str price_str--" + item.priceDynamic}>
                                            <span className="price price--rating--hight">
                                                <span className="curr">$</span> {item.price}
                                            </span>
                                        </span>
                                        <span
                                            className={"rating rating--" + (
                                                item.sellerRating > 75 ? "hight" : (
                                                    item.sellerRating > 50 ? "medium" : "low"
                                                )
                                            )}>
                                            <i className="rating_tooltip_text">
                                                {t("ratingProductTooltip")}
                                            </i>
                                            {item.sellerRating}%
                                        </span>
                                    </div>
                                </a>
                            })}
                        </div>
                    </div>
                </div>
            </SimpleBar>
            <p className="similar_info">
                {t("footerHint")}
            </p>
        </div>
    );
}

export default SimilarProducts;