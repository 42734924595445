import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import './LandingPage/css/remove.css';
import Footer from './LandingPage/Footer.js';
import Header from './LandingPage/Header.js';
import {Trans, useTranslation} from "react-i18next";
import RemoveForm from "./RemoveForm";
import i18next from "i18next";
import {useCookies} from "react-cookie";

function Remove() {
  const {t} = useTranslation();
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [cookies, setCookie] = useCookies(["installUrl"]);

  useState(() => {
      setCookie(
          'installUrl',
          document.location.href,
          {
              "max-age": -1,
              "path": "/"
          }
      );
  }, [])

  return [
    <Helmet key={1} htmlAttributes={{ lang : i18next.language }}>
      <title>{t("removePage.title")}</title>
    </Helmet>,
    <Header disableMenu={true} key={2}/>,
    <div className="wrap clearfix" key={3}>
      <div className="main section section--main remove-page">
        {
          isFormSubmitted ? (
              <div className="container">
                <div className="extension-deleted success">
                  <div className="support_popup_result">
                    <h1>{t("supportFormSubmitted.success")}</h1>
                  </div>
                </div>
              </div>
          ) : (
          <div className="container">
            <div className="row remove-header">
              <h2 className="col-12 banner__title text-center">
                <Trans>removePage.h1</Trans>
                <span className="imga" />
              </h2>
            </div>
            <div className="extension-deleted">
              <h4 className="extension-deleted__title text-center">
                <Trans>removePage.subtitle</Trans>
              </h4>
              <RemoveForm onSubmit={setFormSubmitted}/>
            </div>
          </div>
          )
        }
      </div>
      <div className="footer-empty"/>
    </div>,
    <Footer key={4}/>,
  ]
}

export default Remove;
