import React from "react";
import SimpleBar from "simplebar-react";
import {useTranslation} from "react-i18next";
import NiceSelect from "./NiceSelect";

function SimilarProducts() {
    const {t} = useTranslation('features');
    const favoriteItems = [
        {
            imageUrl: "/img/products/favoriteProductImg0.jpg",
            title: t("favoriteProductTitle0"),
            priceDynamic: "increase",
            price: "1 021.61",
            priceChangeDate: t("aWeekAgo"),
            priceTooltipText: t("priceIncrease")
        },
        {
            imageUrl: "/img/products/favoriteProductImg1.jpg",
            title: t("favoriteProductTitle1"),
            priceDynamic: "decrease",
            price: "205.1",
            priceChangeDate: t("4daysAgo"),
            priceTooltipText: t("priceDropped")
        },
        {
            imageUrl: "/img/products/favoriteProductImg2.jpg",
            title: t("favoriteProductTitle2"),
            priceDynamic: "increase",
            price: "340.44",
            priceChangeDate: t("2daysAgo"),
            priceTooltipText: t("priceIncrease")
        },
        {
            imageUrl: "/img/products/favoriteProductImg3.jpg",
            title: t("favoriteProductTitle3"),
            priceDynamic: "increase",
            price: "488.52",
            priceChangeDate: t("4daysAgo"),
            priceTooltipText: t("priceIncrease")
        },
        {
            imageUrl: "/img/products/favoriteProductImg4.jpg",
            title: t("favoriteProductTitle4"),
            priceDynamic: "decrease",
            price: "586.25",
            priceChangeDate: t("droppedToday"),
            priceTooltipText: t("priceDropped")
        },
        {
            imageUrl: "/img/products/favoriteProductImg5.jpg",
            title: t("favoriteProductTitle5"),
            priceDynamic: "decrease",
            price: "211.66",
            priceChangeDate: t("2daysAgo"),
            priceTooltipText: t("priceDropped")
        },
        {
            imageUrl: "/img/products/favoriteProductImg6.jpg",
            title: t("favoriteProductTitle6"),
            priceDynamic: "decrease",
            price: "364.27",
            priceChangeDate: t("droppedYesterday"),
            priceTooltipText: t("priceDropped")
        },
        {
            imageUrl: "/img/products/favoriteProductImg7.jpg",
            title: t("favoriteProductTitle7"),
            priceDynamic: "increase",
            price: "518.14",
            priceChangeDate: t("overAMonth"),
            priceTooltipText: t("priceIncrease")
        },
        {
            imageUrl: "/img/products/favoriteProductImg8.jpg",
            title: t("favoriteProductTitle8"),
            priceDynamic: "increase",
            price: "873.53",
            priceChangeDate: t("3daysAgo"),
            priceTooltipText: t("priceIncrease")
        },
        {
            imageUrl: "/img/products/favoriteProductImg9.jpg",
            title: t("favoriteProductTitle9"),
            priceDynamic: "increase",
            price: "163.38",
            priceChangeDate: t("increasedToday"),
            priceTooltipText: t("priceIncrease")
        }
    ];
    const filterList = [
        t("sort.all"),
        t("sort.priceDropped"),
        t("sort.byPrice"),
        t("sort.byRating")
    ];

    return (
        <div>
            <div className="title_block title_block--watchlist">
                <p className="title">
                    {t("title.favorite.part1")}<span className="my_products_count"> (10)</span>
                </p>
                <span className="sub_title">{t("title.favorite.part2")}</span>
                <div className="select_wrap">
                    <NiceSelect items={filterList} />
                </div>
            </div>
            <SimpleBar forceVisible="y" autoHide={false} className="goods_block ss-container" id="goods_block_content">
                {favoriteItems.map((item, index) => {
                    return (
                        <a className="goods_item" href="" title={item.title} key={"favorite-product-" + index} onClick={(e) => {e.preventDefault()}}>
                            <div className="goods_item_img">
                                <img alt={item.title} className="img" src={item.imageUrl}/>
                            </div>
                            <div className="goods_item_info">
                                <div className="goods_item_name">{item.title}</div>
                                    <div className="goods_item_price"><div className="price_str">
                                        <span className={"price " + item.priceDynamic}>
                                        <span className="curr">$</span>{item.price}</span>
                                        <span className="price_msg">{item.priceChangeDate}</span>
                                    </div>
                                    <i className="price_tooltip_text">{item.priceTooltipText}</i>
                                </div>
                            </div>
                            <span className="delete_item">×</span>
                        </a>
                    )
                })}
            </SimpleBar>
        </div>
    );
}

export default SimilarProducts;