import React, {Suspense} from 'react';
import LandingPage from './LandingPage/LandingPage.js'
import Agreement from './Agreement.js'
import Policy from './Policy.js'
import Remove from './Remove.js'
import Welcome from './Welcome.js'
import NotFound from "./NotFound.js";
import OnBoarding from "./OnBoarding.js";
import Tracking from "./Tracking.js";
import ExternalRedirect from "./ExternalRedirect.js";
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect,
} from "react-router-dom"
import TagManager from "react-gtm-module";
const isReactSnap = navigator.userAgent === 'ReactSnap';
const Loader = () => (
    <div className="app-loader">
        <div/><div/><div/><div/>
    </div>
);

function App() {
    ExternalRedirect();
    TagManager.initialize({gtmId: 'GTM-KT2B9L2'});
    TagManager.initialize({gtmId: 'GTM-NSWD2DJ'});

    return [
        <Suspense fallback={<Loader />} key={1}>
            <BrowserRouter key={2}>
              <Switch>
                  <Route key={'/'} exact path='/' component={LandingPage}/>
                  <Route key={'/agreement'} path='/agreement' component={Agreement}/>
                  <Route key={'/policy'} path='/policy' component={Policy}/>
                  <Route key={'/remove'} path='/remove' component={Remove}/>
                  <Route key={'/welcome'} path='/welcome' component={Welcome}/>
                  <Route key={'/onboarding'} path='/onboarding' component={OnBoarding}/>
                  <Route path='/404' component={NotFound}/>
                  <Redirect from='*' to='/404' />
              </Switch>
              {isReactSnap ? '' : <Tracking key={3}/>}
            </BrowserRouter>
        </Suspense>,
  ];
}

export default App;
