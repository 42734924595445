import React from "react";
import ExtensionButton from "./ExtensionButton";
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

function Footer(props) {
    const {t} = useTranslation(props.translationNs ? props.translationNs : null)

    return <div className="footer section section--footer">
        <div className="section_inner">
            <div className="copy">{t("footer.copyright")}</div>
            <ExtensionButton isBigButton={false} buttonKey={4}/>
            <div className="mobile_links">
                <div className="agreement">
                    <Link to={"/agreement"} target="_blank">{t("footer.userAgreementLink")}</Link>
                    <Link to={"/policy"} target="_blank">{t("footer.rulesLink")}</Link>
                </div>

            </div>
        </div>
    </div>
}

export default Footer;
