import React from 'react';
import './LandingPage/css/agreement.css'
import {Trans, useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import i18next from "i18next";

function Policy() {
  const {t} = useTranslation('policy');

  return [
      <Helmet key={1} htmlAttributes={{ lang : i18next.language }}>
          <title>{t("pageTitle")}</title>
      </Helmet>,
      <div className="agreement_page">
        <div className="argeement_content">
          <h1>{t("title")}</h1>
          <p>{t("part1")}</p>
          <p>{t("part2")} </p>
          <p>{t("part3")}</p>
          <p>{t("part4")}</p>
          <p>
            <Trans i18nKey="part5" t={t}/>
          </p>
          <p>
            <Trans i18nKey="part6" t={t}/>
          </p>
        </div>
      </div>
  ]
}

export default Policy;
