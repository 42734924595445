import React from "react";
import {Trans, useTranslation} from 'react-i18next';
import {HashLink} from 'react-router-hash-link';
import LanguageSelector from "./LanguageSelector";
import SupportPopupLink from "./SupportPopupLink";

function Header(props) {
    const {t} = useTranslation();
    function scrollToBlock(el, event) {
        let yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        let blockOffset = el.getAttribute('data-offset');
        let yOffset = blockOffset ? parseInt(blockOffset) : -80;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    return (
        <div className="header section section--header">
            <div className="section_inner">
                <a href="/" className="logo">
                    <span className="icon icon--header_logo"/>
                    <span className="text">{t("header.extensionName")}</span>
                </a>
                <ul className="header_nav">
                    <li className={"header_nav_item" + (props.disableMenu ? " hidden" : "")}>
                        <HashLink smooth to={"/#features"} className={"header_nav_link"}
                                  key={"nav-link-section--features"} scroll={el => scrollToBlock(el)}>
                            <Trans>header.mainMenu.features</Trans>
                        </HashLink>
                    </li>
                    <li className={"header_nav_item" + (props.disableMenu ? " hidden" : "")}>
                        <HashLink smooth to={"/#howto"} className={"header_nav_link"} key={"nav-link-section--howto"}
                                  scroll={scrollToBlock}>
                            <Trans>header.mainMenu.advantages</Trans>
                        </HashLink>
                    </li>
                    <li className={"header_nav_item" + (props.disableMenu ? " hidden" : "")}>
                        <HashLink smooth to={"/#faq"} className={"header_nav_link"} key={"nav-link-section--faq"}
                                  scroll={scrollToBlock}>
                            <Trans>header.menu.faq</Trans>
                        </HashLink>
                    </li>
                    <li className={"header_nav_item" + (props.disableMenu ? " hidden" : "")}>
                        <SupportPopupLink />
                    </li>
                    <li className="header_nav_item">
                        <LanguageSelector/>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Header;
