import React from 'react';
import './LandingPage/css/agreement.css'
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import i18next from "i18next";
import Header from "./LandingPage/Header";
import Footer from "./LandingPage/Footer";

function NotFound() {
  const {t} = useTranslation();

  return [
      <Helmet key={1} htmlAttributes={{ lang : i18next.language }}>
          <title>{t("notFoundPage.pageTitle")}</title>
      </Helmet>,
      <Header key={2} />,
      <div className="not_found_page">
          <div className="not_found_content">
              <h1>{t("notFoundPage.title")}</h1>
              <p>{t("notFoundPage.subtitle")}</p>
          </div>
      </div>,
      <Footer key={4} />,
  ]
}

export default NotFound;
