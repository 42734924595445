import React, {useState} from "react";
import {isOpera, isYandex, isChrome} from "react-device-detect";
import {Trans, useTranslation} from "react-i18next";
import ym from "react-yandex-metrika";
import {useCookies} from "react-cookie";
import ReactGA from "react-ga";

const EXTENSION_URL = "https://chrome.google.com/webstore/detail/aliexpress-helper/lmfbagjnfegjafocookgkkjedmmojopj?utm_source=land-redirect-"

function ExtensionButton(props) {
    const {t} = useTranslation();
    const [cookies, setCookie] = useCookies(["utmParams"]);
    const browserList = [
        {
            name: "other",
            browserText: "extension.button.chrome"
        },
        {
            name: "chrome",
            browserText: "extension.button.chrome"
        },
        {
            name: "opera",
            browserText: "extension.button.opera"
        },
        {
            name: "yandex",
            browserText: "extension.button.yandex"
        },
    ];
    const [browser, setBrowser] = useState(detectBrowser);

    function detectBrowser()
    {
        let key;
        switch (true) {
            case isChrome:
                key = 1;
                break;
            case isOpera:
                key = 2;
                break;
            case isYandex:
                key = 3;
                break;
            default:
                key = 0
        }

        return browserList[key]
    }

    function trackInstallEvents() {
        ym("reachGoal", "INSTALL_BTN_" + props.buttonKey, cookies.utmParams);
        ReactGA.event({
            'category': 'Landing',
            'action': 'Install button click',
            'label':  'Button ' + props.buttonKey + ', ' + browser.name
        });

    }

    return (
        <div className="button_wrap" onClick={trackInstallEvents}>
            <a href={EXTENSION_URL+browser.name} target={'_blank'} className={
                props.isBigButton
                    ? "button button--install button--main button--install--big"
                    : "button button--install button--main"
            }>
                <span className="button_bg">
                    <span className={
                        props.isBigButton ?
                        "icon icon--browser icon--"+browser.name+"--big" :
                        "icon icon--browser icon--"+browser.name+"--small"
                    } />
                    <span className="button_text">
                        <span className="text"><Trans>extension.button.title</Trans></span>
                        <span className="info"><Trans>extension.button.subtitle</Trans></span>
                    </span>
                </span>
            </a>
            <p className="button_wrap_info">{t(browser.browserText)}</p>
        </div>
    );
}

export default ExtensionButton;