import React, {useEffect, useState} from "react";
import {Trans} from "react-i18next";
import {HashLink} from "react-router-hash-link";
import SupportPopup from "./SupportPopup";

function SupportPopupLink(props) {
    const [isPopupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        if(window.location.hash === '#support'){
            openPopup();
        }
    })

    function closePopup() {
        setPopupOpen(false);
        window.location.hash = '';
    }

    function openPopup() {
        setPopupOpen(true);
    }

    return (
        <div>
            <HashLink
                smooth to={"/#support"}
                className={"header_nav_link header_nav_link--support"}
                key={"nav-link-section--faq"}
                onClick={openPopup}
            >
                <Trans>header.mainMenu.support</Trans>
            </HashLink>
            <SupportPopup isPopupOpen={isPopupOpen} closePopup={closePopup} />
        </div>
    )
}

export default SupportPopupLink;