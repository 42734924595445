import React, {useState} from "react";

function NiceSelect(props) {
    const [dropdownShowed, setDropdownShowed] = useState(false);
    const [selectedItem, setSelectedItem] = useState(0);

    function toggleDropdown() {
        setDropdownShowed(!dropdownShowed);
    }

    function onSelectItem(index) {
        setSelectedItem(index);
    }

    return (
        <div className={"ali-nice-select form-control " + (dropdownShowed ? "open" : "")} tabIndex="0" onClick={toggleDropdown}>
            <span className="current">{props.items[selectedItem]}</span>
            <ul className="list">
                {props.items.map((item, index) => {
                    return <li
                        key={index}
                        data-value={item}
                        className={"option" + (selectedItem === index ?  " selected focus" : "")}
                        onClick={() => onSelectItem(index)}
                    >{item}</li>
                })}
            </ul>
        </div>
    )
}

export default NiceSelect;