import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import SupportForm from "./SupportForm.js"

function SupportPopup(props){
    const {t} = useTranslation();
    const [isFormSubmitted, setFormSubmitted] = useState(false);

    function closePopup() {
        props.closePopup();
        setFormSubmitted(false);
    }

    return (
        <div id={"support"}>
            <div className={"support_popup_overlay"  + (props.isPopupOpen ? " open" : "")}  onClick={closePopup}/>
            <div className={"support_popup_content" + (props.isPopupOpen ? " open" : "")}>
                <span className="support_popup_content_close" onClick={closePopup}/>
                {
                    isFormSubmitted ? (
                        <div className="support_popup_result">
                            <h1>{t("supportFormSubmitted.success")}</h1>
                        </div>
                    ) : (
                        <div>
                            <div className="support_popup_title">
                                <p className="title">
                                    {t("supportPopup.popup.title")}
                                </p>
                                <p className="info">
                                    {t("supportPopup.popup.subtitle")}
                                </p>
                            </div>
                            <SupportForm onSubmit={setFormSubmitted}/>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default SupportPopup;
