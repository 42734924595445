import React, {useEffect} from "react";
import {Formik, Field, Form} from 'formik';
import {useTranslation} from "react-i18next";

const RemoveForm = (props) => {
    const formUrl = "https://api.track4you.tech/api/fe/plugins/removes";
    const {t} = useTranslation();
    const queryParams = new URLSearchParams(window.location.search);
    const isReactSnap = navigator.userAgent === 'ReactSnap';

    useEffect(() => {
        if (!isReactSnap) {
            sendForm({
                extensionId: queryParams.get('extensionId'),
                utm: queryParams.get('utm')
            })
        }
    })

    function sendForm(bodyParams) {
        fetch(formUrl, {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: new URLSearchParams(bodyParams).toString()
        });
    }

    return (
        <div>
        <Formik
            initialValues={{
                type: '',
                email: '',
                comment: '',
            }}
            onSubmit={async (values) => {
                sendForm({
                    extensionId: queryParams.get('extensionId'),
                    utm: queryParams.get('utm'),
                    "App_remove_log[email]": values.email,
                    "App_remove_log[comment]": values.comment,
                    type: values.type
                });
                props.onSubmit(true);
            }}
        >
            {({values}) => (
            <Form name={"App_remove_log"} className="extension-deleted__form">
                <div className="extension-deleted__form__reason">
                    <Field type="radio" name="type"  value="1" id={"didNotKnowHowToUse"}/>
                    <label htmlFor="didNotKnowHowToUse">{t("removePage.reason.didNotKnowHowToUse")}</label>
                </div>
                <div className="extension-deleted__form__reason">
                    <Field type="radio" name="type" value="2" id={"wasConflictWithOtherExtensions"}/>
                    <label htmlFor="wasConflictWithOtherExtensions">{t("removePage.reason.wasConflictWithOtherExtensions")}</label>
                </div>
                <div className="extension-deleted__form__reason">
                    <Field type="radio" name="type" value="3" id={"problemWithBrowser"}/>
                    <label htmlFor="problemWithBrowser">{t("removePage.reason.problemWithBrowser")}</label>
                </div>
                <div className="extension-deleted__form__reason">
                    <Field type="radio" name="type" value="4" id={"problemWithAli"}/>
                    <label htmlFor="problemWithAli">{t("removePage.reason.problemWithAli")}</label>
                </div>
                <div className="extension-deleted__form__reason">
                    <Field type="radio" name="type" value="5" id={"otherReason"}/>
                    <label htmlFor="otherReason">{t("removePage.reason.otherReason")}</label>
                    <div className={values.type === "5" ? "" : "hidden"}>
                        <Field
                            id="App_remove_log_comment"
                            name="comment"
                            as="textarea"
                            placeholder={t("supportPopup.message")}
                        />
                    </div>
                </div>
                <button type="submit" className="button button--default support_popup_form_button">
                    <span className="button_bg">
                        <span className="button_text">{t("removePage.sendButton")}</span>
                    </span>
                </button>
            </Form>
            )}
        </Formik>
    </div>
    )
};

export default RemoveForm;